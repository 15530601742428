jQuery(document).ready(function ($) {


  timer = setInterval(function(){
    if($('#bboxdonation_gift_txtOtherAmountButtons').length){
      $('#bboxdonation_gift_txtOtherAmountButtons').attr('placeholder', 'Other Amount');
        clearInterval(timer)
    }
}, 200);


  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  /**
   * get the slug from the selected option
   * append and refresh the page or forward to the current page with url params
   */
  $("#selectShortcodeContent").on("change", function (event) {
    window.location =
      window.location.origin +
      window.location.pathname +
      "?selectcat=" +
      event.target.value;

      // var url = new URL(window.location);
      // url.searchParams.selectcat = event.target.value;
      // window.location = url.href;
  });



  /**
   * get the slug from the selected option
   * append and refresh the page or forward to the current page with url params
   */
  $("#shortcodeSearchBox").on("blur", function (event) {
    window.location =
      window.location.origin +
      window.location.pathname +
      "?search=" +
      event.target.value;

      // var url = new URL(window.location);
      // url.searchParams.search  = event.target.value;
      // window.location = url.href;
  });

  $("#shortcodeSearchBox").on('keypress', function(event){
    if( event.which == 13 ){
      window.location =
      window.location.origin +
      window.location.pathname +
      "?search=" +
      event.target.value;

      // var url = new URL(window.location);
      // url.searchParams.search = event.target.value;
      // window.location = url.href;

    }
  })

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
});
